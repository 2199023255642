import { Box, Container, List, ListItem, Typography } from "@mui/material";


const boldFontStyle = {
    color: '#000',
    fontSize: '20px',
    fontWeight: 600,
}

const marginStyle = {
    margin: "10px 0px"
}

const PrivacyPolicy = () => {

    return (
        <Container sx={{ marginTop: '25px',paddingBottom:'20px'}}>
            <Typography sx={boldFontStyle}>Privacy Policy</Typography>
            <Typography sx={marginStyle}>
                Weddingloan.com (WL) is a Website owned by Matrimony.com. This page is used to inform visitors/ Users regarding our policies with the collection, use, and disclosure of Personal Information for anyone using the Website of WL
                (<Box component="span" sx={{ fontWeight: 'bold' }}>"Weddingloan.com"</Box>)
                . By registering/ using WL Website, you consent to the terms of our privacy policy (<Box component="span" sx={{ fontWeight: 'bold' }}>"Privacy Policy"</Box>) in addition to our Terms of Service. We encourage you to read this Privacy Policy regarding the collection, use, and disclosure of your information from time to time to keep yourself updated with the changes & updated that we make to this Privacy Policy.
            </Typography>
            <Typography sx={boldFontStyle}>Personal Identification Information</Typography>
            <Typography sx={marginStyle}>
                If you choose to use our Service, then you consent and agree to the collection and use of information in relation to this policy. The Personal Identification Information that we collect is used for providing and improving the Service. We will not use or share your information with anyone except as described in this Privacy Policy.
            </Typography>
            <Typography sx={marginStyle}>
                The personal information you provide on WL when you register, name, date of birth, email address, mobile number, PAN card number, permanent residential address, Your salary amount, current residential address, nature of Your employment, place of employment. You can choose not to provide certain information but then you might not be able to take advantage of many of our services and features. We neither collect nor store your biometric information.<br />
            </Typography>
            <Typography sx={marginStyle}>
                We might receive information about you from other sources and add it to our account information.
            </Typography>

            <Typography mt={4} sx={boldFontStyle}>Non-personal Identification Information</Typography>
            <Typography sx={marginStyle}>
                We may collect non-personal identification information about users whenever they interact with our site.
                Non-personal identification information may include the type of mobile phone and technical
                information about users, such as the operating system and the Internet service providers
                utilized including IP address and other similar information.
            </Typography>
            <Typography mt={2} sx={boldFontStyle}>Usage and Technical Information</Typography>
            <Typography sx={marginStyle}>
                We collect the information about how you interact with our Service. This information may include your IP address, geographical location, browser type, referral source, length of visit, pages viewed and items clicked.
            </Typography>
            <Typography mt={4} sx={boldFontStyle}>We Collect Cookies</Typography>
            <Typography sx={marginStyle}>
                We receive and store certain types of information whenever you interact with us. For example, like, we use "cookies" and we obtain certain types of information when your web browser accesses the Website or advertisements and other content served by or on behalf of the Website on other websites. A cookie is a piece of data stored on the user's computer tied to information about the user. We may use both session ID cookies and persistent cookies. For session ID cookies, once you close your browser or log out, the cookie terminates and is erased. A persistent cookie is a small text file stored on your computer’s hard drive for an extended period of time. Session ID cookies may be used by WL.com to track user preferences while the user is visiting the website. They also help to minimize load times and save on server processing.
            </Typography>
            <Typography mt={3}>
                Most browsers will tell you how to prevent your browser from accepting new cookies, how to have the browser notify you when you receive a new cookie and how to disable cookies altogether. Additionally, you can disable or delete similar data used by browser add-ons, such as Flash cookies, by changing the add-on's settings or visiting the website of its manufacturer.            </Typography>

            <Typography mt={2}>However, because cookies allow you to take advantage of some of the Website’s essential features, we recommend that you leave them turned on. For instance, if you block or otherwise reject our cookies, you may not be able to use any Website products and services that require you to Sign in.
            </Typography>
            <Typography sx={boldFontStyle} mt={3}>
                How do We Use The Information?
            </Typography>
            <Typography mt={2}>
                WL collects your information when you register for an account, when you use its products or services, visit its Website's pages. When you register with WL, you are asked for your phone number. Once you register at the Website and sign in you are not anonymous to us. Also, you are asked for your contact number during registration and may be sent SMS, notifications about our services to your wireless device. Hence, by registering you authorize the WL (including its business partners and affiliates) to send texts and email alerts to you with your login details and any other service requirements, including promotional mails and SMS, even if you have registered yourself under DND or DNC or NCPR services. Your authorization shall be valid as long as your account is not deactivated or unless you withdraw your consent.</Typography>
            <Typography sx={boldFontStyle} mt={5}>
                Purpose for collecting information</Typography>
            <List sx={{ listStyleType: 'disc !important', marginLeft: '45px' }}>
                <ListItem sx={{ display: 'list-item' }}>Assist us or our business partners in facilitating and delivering services to you, communicate with you about products, services and promotional offers.</ListItem>
                <ListItem sx={{ display: 'list-item' }}>Respond to queries, or requests submitted by you, and resolve your grievances/issues/problems with any services supplied to you                    </ListItem>
                <ListItem sx={{ display: 'list-item' }}>
                    Administer or otherwise carry out our obligations in relation to any agreement with our business partners.                </ListItem>
                <ListItem sx={{ display: 'list-item' }}>
                    Send you information about special promotions or offers. We might also tell you about new features or products/services. These might be our own offers or products/services, or third-party offers or products/services with whom WL has a tie-up.
                </ListItem>
                <ListItem sx={{ display: 'list-item' }}>
                    Use your information for internal analysis and to provide you with location-based services, such as advertising, search results, and other personalized content.                </ListItem>
                <ListItem sx={{ display: 'list-item' }}>Use this information to improve our platform, prevent or detect fraud or abuses of our Website and enable third parties to carry out technical, logistical or other functions on our behalf. We may combine information we get from you with information about you we get from third parties.</ListItem>
                <ListItem sx={{ display: 'list-item' }}>
                    Send you notices, communications, and recommend services that might be of interest to you, update our records and generally maintain your accounts with us, display content and customer reviews.
                </ListItem>
                <ListItem sx={{ display: 'list-item' }}>
                    When you provide your contact details, we will use it to send you general notices or important news about your account, request your feedback or opinions and provide updates on special deals and offers that might interest you.
                </ListItem>
                <ListItem sx={{ display: 'list-item' }}>
                    File storage permissions on android/iOS and Website for uploading customer documents are obtained for the purpose of processing customer applications by our Partners.
                </ListItem>
                <ListItem sx={{ display: 'list-item' }}>
                    WL Website may, based on your consent, also access your mobile device including camera for the purpose of service facilitation, ease of access and logging in to our Website for the various services opted by you.

                </ListItem>
                <ListItem sx={{ display: 'list-item' }}>
                    As otherwise provided in this Privacy Policy and in compliance with the applicable laws.                    </ListItem>
                <ListItem sx={{ display: 'list-item' }}>
                    Some features of this Website or our Services will require you to furnish your personally identifiable information as provided by you under your account section on our Website.                    </ListItem>

            </List>
            <Typography sx={boldFontStyle}>
                Disclosure to Third Parties</Typography>
            <Typography mt={2}>
                WL will not sell or rent or otherwise disclose your information for commercial purposes to anyone in a way that is contrary to the commitments made and/or other than as set forth in this Privacy Policy. Notwithstanding the foregoing, we may share your information to third parties including our Business Partner (Banks/NBFCs), CICs and Service Providers (
                <Box component="a"
                    target="_blank"
                    href="https://www.ltfs.com/our-products/consumer-loan/fees-charges">https://www.ltfs.com/our-products/consumer-loan/fees-charges ,<Box
                        component="a"
                        target="_blank" href="https://sachet.rbi.org.in/">https://sachet.rbi.org.in/</Box></Box>)
                we have a tie up with and any of our affiliates, for the purposes as set out in this Privacy Policy
            </Typography>
            <Typography mt={2}>
                These third parties are required to handle your information using the same level of care and confidentiality as is followed by WL and any accessing or processing of your information by these third parties is
                in accordance with contractual terms, applicable laws and our instructions and subject to your consent.
                For the purposes of this paragraph, “Affiliate” shall mean, as to any Person, any other Person that,
                directly or indirectly, controls, or is controlled by, or is under common control with, such Person.
                (The term “control” (including, with its correlative meanings, “controlled by” and “under common
                control with”) shall mean the possession, directly or indirectly, of the power to
                direct or cause the direction of management or policies of a Person, whether through
                the ownership of securities or partnership or other ownership interests,
                by Contract or otherwise. The term "Person" includes any natural person, corporation,
                partnership, limited liability company, trust, unincorporated association, or any other
                entity).
            </Typography>
            <Typography mt={2}>
                WL may also share, and/or transfer your personally identifiable information to any successor-in-interest as a result of a sale of any part of WL business or upon the merger, reorganization, or consolidation of it with another entity on a basis that it is not the surviving entity.
            </Typography>
            <Typography mt={2}>
                We limit the collection and use of your personal information. We may make anonymous or aggregate personal information and disclose such data only in a non-personally identifiable manner. Such information does not identify you individually. Access to your Account information and any other personal identifiable information is strictly restricted and used only in accordance with specific internal procedures, and for the purposes set out in this Privacy Policy, in order to operate, develop or improve our services. We may use third party service providers to enable you to provide our services and we require such third parties to maintain the confidentiality of the information we provide to them, under our contracts with them.
            </Typography>
            <Typography mt={2}>
                We may also share your information, without obtaining your prior written consent, with government agencies mandated under the law to obtain information for the purpose of verification of identity, or for prevention, detection, investigation including cyber incidents, prosecution, and punishment of offences, or where disclosure is necessary for compliance of a legal obligation. You agree and consent for the Website to disclose your information, if so required, under applicable law.            </Typography>
            <Typography mt={4}>
                There are a number of products/services such as loans, offered by third Parties on the Website, such as lenders, banks. If you choose to apply for these separate products or services, disclose information to these providers, then their use of your information is governed by their privacy policies in addition to the Privacy Policy of the Website. WL is not responsible for their privacy policies. We encourage you to visit and read about the privacy notices and procedures adopted by these third parties/providers, when you apply for their products or services. WL holds no responsibility for the content of the privacy policies or terms of use etc. of these third party websites.
            </Typography>
            <Typography mt={4} sx={boldFontStyle}>
                Data Storage            </Typography>
            <Typography mt={2}>
                WL  stores your personal information only at servers located in India. Information of our customers are retained for meeting the servicing requirements of our customers, except as mandated in our arrangements with our business partners to provide services to you, unless consent is withdrawn by you. Retention of information is done as per this policy in compliance with applicable law/regulatory requirements in India.            </Typography>
            <Typography mt={2}>
                In following situation, WL  may retain your information for an extended period (i) in case of requirement of any investigations under law or as part of any requirements before courts/tribunals/forums/commissions etc; (ii) to enhance/improve our products/services and (iii) as required under contractual arrangements with business partners.
            </Typography>
            <Typography mt={4} sx={boldFontStyle}>
                Provisions in line with RBI’s Digital Lending Guidelines.
            </Typography>
            <Typography mt={2}>
                Any information of our customers taken for the purpose of facilitating digital lending services are retained for a period of eight years for meeting the servicing requirements of our customers. This duration will be determined from the most recent instance of customer accessing WL services. Please note that WL may need to retain basic identification details to comply with applicable legal and compliance requirements.                </Typography>
            <Typography mt={4} sx={boldFontStyle}>
                What Is Your Control Over Your Personal Information That’s Collected And Used Online?
            </Typography>
            <Typography mt={2}>
                It's important to note that the information we use about you helps us provide you with products, services and experiences that benefit you. You have the ability to control how your non-personal information is collected and used online.
            </Typography>
            <Typography mt={2}>
                You also have the ability to choose what personal information, including what sensitive personal information (i.e. your financial information) you provide to us, restrict disclosure of your information to third parties, however, please note that this may affect your seamless access to such product/ service as opted by you. However, if you choose not to provide all of the mandatory information and data that is requested of you, we may not be able to provide you with the Services that you have subscribed to.
            </Typography>
            <Typography mt={2}>
                We believe you should be able to choose what kinds of information you receive via email/SMS. If you do not want to receive marketing materials by email/SMS, just indicate your preference on the contact information for your account or the 'opt-out' or unsubscribe link provided in our marketing emails and you can also write to us at care@weddingloan.com. Please note that it may take about 10 days to process your request. Please keep in mind that we will continue to notify you by email /SMS/via phone calls regarding your services with us, even after you have opted out.
            </Typography>
            <Typography mt={4}>
                You can review the information that you have provided to us by logging into your account at the Website and correct or amend any personal information or sensitive personal data or information to ensure that the information or data you provided us is accurate and/or not deficient. WL is not responsible for the authenticity of any personal information or sensitive personal data or information supplied to it by you or any third party.
            </Typography>
            <Typography mt={2} sx={boldFontStyle}>Revocation of Consent and Deletion of Data</Typography>
            <Typography mt={3}>
                If you feel that we do not require the retention of your personal information or if you ask us to delete or remove your personal data where you think we do not have the right to process it, we shall destroy or delete such Customer information. You may, at any time while availing of our Services or otherwise, withdraw the consent
                given earlier to us to collect and use your sensitive personal data or information by writing to us
                at
                <Box component="a"
                    target="_blank" href="consentrevocation@weddingloan.com"> consentrevocation@weddingloan.com.</Box> Post successful verification of your consent withdrawal request, the same shall be processed within a period of 45 days from the date of receipt of such request subject to successful verification. In some cases, if you may have taken WL services we may not be in a position to delete your data. This is because as per the applicable law, we may be required to retain your data, for us/our partner banks/NBFCs to continue to provide services availed by you. We assure that such retained information shall be protected following all applicable cyber security norms.            </Typography>
            <Typography mt={2}>
                However, in the case of you withdrawing such consent, WL shall have the option to stop providing you the Services for which the information was sought. You will not be eligible for a refund of any fees paid for any service in such an event and you agree that the company shall not be liable to you for the same in any manner whatsoever.
            </Typography>
            <Typography mt={2} sx={boldFontStyle}>Log Files</Typography>
            <Typography mt={2}>
                Like most standard websites, we use log files. This information may include internet protocol (IP) addresses, browser type, internet service provider (ISP), referring/exit pages, platform type, date/time stamp, and number of clicks to analyze trends, administer the site, track user's movement in the aggregate, and gather broad demographic information for aggregate use. We may combine this automatically collected log information with other information we collect about you. We do this to improve the services we offer to you, to improve marketing, analytics or site functionality.            </Typography>
            <Typography mt={4} sx={boldFontStyle}>Data Security</Typography>
            <Typography mt={3}>
                WL is an organization ensuring applicable compliance standards on information security and understands that the confidentiality, integrity, and availability of your information are vital to our business operations and our own success. We employ appropriate technical and organizational security measures at all times to protect the information we collect from you. We use multiple electronic, procedural, and physical security measures to protect against unauthorized or unlawful use or alteration of information, and against any accidental loss, destruction, or damage to information. We have put in place appropriate security measures to deal with any suspected data security breach However, no method of transmission over the Internet, or method of electronic storage, is 100% secure. Therefore, we cannot guarantee its absolute security. Further, you are responsible for maintaining the confidentiality and security of your login id and password, and may not provide these credentials to any third party. During any security breach all the relevant stakeholders (both internal and relevant partners as well as statutory authorities) shall be informed with the relevant information as may be required by the applicable laws.
            </Typography>
            <Typography mt={5} sx={boldFontStyle}>For European Union Members (EU)</Typography>
            <Typography mt={2}>If you are located in the EU, you will be asked to provide consent to the collection, processing, and sharing of your personal information. Personal information means any information related to an identified or identifiable natural person. You have the right to share and access your personal information and right to withdraw consent for sharing your personal information at any point of time and right to erase your personal information subject to applicable laws. for sharing your personal information at any point of time. You can withdraw your consent provided by contacting us. Your personal information may be stored in databases located outside of the EU including in India. Where we transfer personal data outside of EU, we either transfer personal information to countries that provide an adequate level of protection or we have appropriate safeguards in place. We may require proof of or need to verify your identity before we can give effect to these rights. To request to review, update, or delete your personal information, please submit a request form by sending an email to care@Weddinggiftbox. We may share your information with third parties who are an anti-fraud solution provider(s) located in UK. They help us to ensure we keep you safe from scammers and fraudster.</Typography>
            <Typography mt={5} sx={boldFontStyle}>Third-Party Advertisers and Links to Other Websites</Typography>
            <Typography mt={2}>Third Party Advertising</Typography>
            <Typography mt={2}>We may use third-party advertising companies and/or ad agencies to serve ads when you visit our Website. These companies may use information (excluding your name, address, email address, or telephone number) about your visits to this Website in order to provide advertisements on this Website and other third-party websites about goods and services that may be of interest to you.</Typography>
            <Typography mt={2}>We use third-party service providers to serve ads on our behalf across the internet and sometimes on this Website. They may collect anonymous information about your visits to Website, and your interaction with our products and services. They may also use information about your visits to this and other Websites for targeted advertisements for goods and services. This anonymous information is collected through the use of a pixel tag, which is industry standard technology used by most major Websites. No personally identifiable information is collected or used in this process.</Typography>
            <Typography mt={4} sx={boldFontStyle}>How Long Do We Keep Your Information?</Typography>
            <Typography mt={2}>As stipulated in the Privacy Policy we will retain the information we collect from users under the following circumstances:</Typography>
            <Typography mt={2}>For as long as the users subscribe to our services to meet their suitable purpose(s) for which it was collected, for the sake of enforcing agreements, for performing audits, for resolving any form of disputes, for establishing legal defences, for pursuing legitimate businesses and to comply with the relevant applicable laws.</Typography>
            <Typography mt={4} sx={boldFontStyle}>What are the Security Precautions in respect of your personal information?
            </Typography>
            <Typography mt={2}>
                We aim to protect your personal information through a system of organizational and technical security measures. We have implemented appropriate internal control measures designed to protect the security of any personal information we process. However, please also remember that we cannot guarantee that the internet itself is 100% secure. Once your information is in our possession, we adhere to security guidelines protecting it against unauthorised access.            </Typography>
            <Typography mt={4} sx={boldFontStyle}>Links to Other Websites</Typography>
            <Typography mt={2}>There might be other sites relating to our Banking and Non Banking partners linked to WL and other affiliates. Personal information that you provide to those sites is not our property or responsibility. These affiliated sites may have different privacy practices and we encourage you to read their privacy policies of these websites when you visit them. WL holds no responsibility for the content of the privacy policies or terms of use etc. of these third-party websites.</Typography>
            <Typography mt={2} sx={boldFontStyle}>Changes in this Privacy Policy</Typography>
            <Typography mt={2}>WL reserves the right to change this policy from time to time, at its sole discretion. We may update this privacy policy to reflect changes to our information practices. We encourage you to periodically review. Checking the effective date below allows you to determine whether there have been changes since the last time you reviewed the statement.</Typography>
            <Typography mt={2} sx={boldFontStyle}>Nodal Grievance Officer</Typography>
            <Typography mt={2}>The Company has appointed a Nodal Grievance Officer to redress customer grievances relating to any digital lending-related complaints. The name and contact details of the Nodal Grievance Officer are provided below :
            </Typography>
            <Typography mt={2}>Name:
                <Typography component="span" > Mr Anand Vasudev</Typography>
            </Typography>
            <Typography mt={1} sx={{ display: 'flex' }}>Address:
                <Typography ml={1}>    M/S. Matrimony.com Limited, No. 94<br />
                    TVH Beliciaa Towers, Tower 2, 5th floor,<br />
                    MRC Nagar, Chennai - 600028</Typography>
            </Typography>
            <Typography mt={1}>Email:
                <Typography component="span"> grievanceofficer@matrimony.com</Typography>
            </Typography>
            <Typography mt={1}>Call at: (Lines are open Mon-Sat from 9:30am to 6:30pm)</Typography>
            <Typography mt={2}>If you have questions, concerns, or suggestions regarding our Privacy Policy, we can be reached using the contact information on our Contact Us page or at </Typography>
            <Typography mt={2} sx={boldFontStyle}>Data Grievance Officer</Typography>
            <Typography mt={2}>In case you have any grievances or want to address any discrepancy with respect to the processing of any of the information/data you provided to WL, please contact our Data Grievance Officer. The name and contact details of the Grievance Officer are provided below:
            </Typography>
            <Typography mt={4}>
                Name: Mr. Anand Vasudev</Typography>
            <Typography mt={1}>
                Designation:<br /></Typography>
            <Typography mt={1}>
                Address: M/s. Matrimony.com Limited,</Typography>
            <Typography mt={1}>
                No.94, TVH Beliciaa Towers, Tower - 2,
            </Typography>
            <Typography mt={1}>
                5th Floor, MRC Nagar, Chennai,
            </Typography>
            <Typography mt={1}>
                Tamil Nadu – 600028<br /></Typography>
            <Typography mt={1} mb={2}>
                Email: <Typography component="a" href="" sx={{ color: "blue" }}>grievanceofficer@matrimony.com</Typography>
            </Typography>
            This Privacy Policy was last updated on:   19 September 2024<br />
            <Typography mt={1}>
                This Privacy Policy is prepared by Legal Department of MCL.
            </Typography>
        </Container>

    )
}

export default PrivacyPolicy;