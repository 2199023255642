import { Grid, Slider, FormControl, TextField, Typography, InputAdornment } from "@mui/material";
import { EMICACULATORQUERY } from "../query/EmiCalculatorQuery";
import { useMutation } from '@apollo/client';
import { useState } from "react";
import AppButton from "./AppButton";
import { convertNumberToWords } from "../utils";


interface ErrorProps {
    error: boolean
    message: string | null
}

const EmiCardView = () => {
    const [calculateEmiValue, { data: emiResponse }] = useMutation(EMICACULATORQUERY);
    const [emiObj, setEmiObj] = useState({
        loanAmount: "",
        tenure: 0,
        interestRate: 0
    })


    const [loanAmounterror, setloanAmountError] = useState<ErrorProps>({
        error: false,
        message: null
    })

    const [error, setError] = useState<ErrorProps>({
        error: false,
        message: null
    })



    const calculateEmi = (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        event.stopPropagation();

        if (!emiObj.loanAmount) {
            setloanAmountError({
                error: true,
                message: "Please Enter Loan Amount"
            })
            return
        }
        let emiInput = {
            loanAmount: emiObj.loanAmount,
            tenure: emiObj.tenure.toString(),
            interestRate: emiObj.interestRate.toString()
        }
        calculateEmiValue({
            variables: {
                input: emiInput
            }
        }).then(({ data: emiResponse }) => {

            if (emiResponse?.emiCalculator.emiCalculatorErrors.length > 0) {

                setError({
                    error: true,
                    message: emiResponse?.emiCalculator.emiCalculatorErrors[0]?.message
                })
            }
        })
    }


    const handleEmiValues = (e: any) => {
        if (e.target.name === "loanAmount" && e.target.value.length > 0) {

            setloanAmountError({
                error: false,
                message: null
            })
        }


        setError({
            error: false,
            message: null
        })
        setEmiObj({ ...emiObj, [e.target.name]: e.target.value })
    }


    const emiCardView = () => {
        return (
            <div  className="emi-section-view mt-3" id="emiSection">
                <div className="emi-card-view">
                    <div>
                        <div className="heading">Calculate your loan EMI</div>
                        <div>Knowing EMI in advance would also help you plan your finances
                            before taking a personal loan.</div>
                    </div>
                </div>
                <div className="emi-cal-card-view">
                    <Grid container spacing={3} className="">
                        <Grid item lg={5} md={6} sm={12} xs={12}>
                            <form noValidate onSubmit={calculateEmi}>
                                {/* <Form.Group controlId="interestInput">
                                    <Form.Label>Loan amount</Form.Label>
                                    <Form.Control required type="text" onChange={handleEmiValues} name="amount" />
                                    <Form.Control.Feedback type="invalid">Please provide amount.</Form.Control.Feedback>
                                </Form.Group> */}
                                <FormControl fullWidth>
                                    <TextField
                                        name="loanAmount"
                                        label="Loan amount"
                                        value={emiObj.loanAmount}
                                        onChange={handleEmiValues}
                                        helperText={loanAmounterror.message}
                                        error={loanAmounterror.error}
                                        
                                        // slotProps={{
                                        //     input: {
                                        //         startAdornment: (
                                        //             <InputAdornment position="start">
                                        //                 kg
                                        //             </InputAdornment>
                                        //         ),
                                        //     },
                                        // }}

                                    />
                                    <div className="fs-12">{emiObj.loanAmount && convertNumberToWords(emiObj.loanAmount)}</div>
                                </FormControl>
                                <div className="mt-3">
                                    <div className="d-flex jc-sb">
                                        <div>Tenure</div>
                                        <div>{emiObj.tenure} Years</div>
                                    </div>
                                    <Slider
                                        name="tenure"
                                        value={emiObj.tenure}
                                        onChange={handleEmiValues}
                                        min={0}
                                        max={30}
                                        aria-label="Default" valueLabelDisplay="auto" />
                                </div>
                                <div className="mt-3">
                                    <div className="d-flex jc-sb">
                                        <div>Interest rate%</div>
                                        <div>{emiObj.interestRate}%</div>
                                    </div>
                                    <Slider
                                        name="interestRate"
                                        value={emiObj.interestRate}
                                        onChange={handleEmiValues}
                                        step={0.5}
                                        min={0}
                                        max={30}
                                        aria-label="Default" valueLabelDisplay="auto" />
                                    {/* <Form.Range min={0} max={100} step="1"
                                        name="interest"
                                        value={emiObj.interest}
                                        onChange={handleEmiValues} /> */}
                                </div>
                                <AppButton text="Calculate" className="app-btn w-100 mt-25" type="submit" />
                                {error.error && <Typography sx={{ marginTop: "12px", color: "red" }}>{error.message}</Typography>}

                            </form>
                        </Grid>
                        <Grid item lg={7} md={6}>
                            <div className="calculated-values-card-view">
                                <Grid container spacing={2} className="gy-3">
                                    <Grid item lg={6} md={12} sm={12} xs={12}>
                                        <div className="emi-values-card-view">
                                            <div>Total amount payable</div>
                                            <div className="fw-bold mt-10">{emiResponse && "₹"} {emiResponse ? emiResponse?.emiCalculator?.emiCalculations?.totalAmountPayable : 0}</div>
                                            <div>Principal + Interest</div>
                                        </div>
                                    </Grid>
                                    <Grid item lg={6} md={12} sm={12} xs={12}>
                                        <div className="emi-values-card-view">
                                            <div>Monthly EMI</div>
                                            <div className="fw-bold mt-10">{emiResponse && "₹"} {emiResponse ? emiResponse?.emiCalculator?.emiCalculations?.monthlyEmi : 0}</div>
                                        </div>
                                    </Grid>
                                    <Grid item lg={6} md={12} sm={12} xs={12}>
                                        <div className="emi-values-card-view">
                                            <div>Principal amount</div>
                                            <div className="fw-bold mt-10">{emiResponse && "₹"} {emiResponse ? emiResponse?.emiCalculator?.emiCalculations?.principalAmount : 0}</div>
                                        </div>
                                    </Grid>
                                    <Grid item lg={6} md={12} sm={12} xs={12}>
                                        <div className="emi-values-card-view">
                                            <div>Total Interest payable</div>
                                            <div className="fw-bold mt-10">{emiResponse && "₹"} {emiResponse ? emiResponse?.emiCalculator?.emiCalculations?.totalInterestPayable : 0}</div>
                                        </div>
                                    </Grid>
                                </Grid>
                            </div>
                        </Grid>
                    </Grid>
                </div>
            </div>
        )
    }

    return (
        <>
            {emiCardView()}
        </>
    )
}

export default EmiCardView;