import Navbar from "../../components/Navbar/Navbar";
import { FormControl, TextField, InputLabel, Select, MenuItem, Snackbar } from "@mui/material";
import leftArrow from "../../assets/leftArrow.svg";
import { EMPLOYMENTTYPESLIST, ORGANISATIONNAMELIST, SALARYMODELSIT } from "../../query/queries";
import { useQuery, useMutation, useLazyQuery } from "@apollo/client";
import { PROFILEDETAILSQUERY } from "../../query/ProfileDetails/profileDetailsQuery";
import Autocomplete, { createFilterOptions } from '@mui/material/Autocomplete';
import { UPDATE_EMPLOYEE_DETAILS } from "../../query/UserDetails/userDetailsQuery";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import AppButton from "../../components/AppButton";
import AsyncSelect from "../../components/AsyncSelect";
import { convertNumberToWords } from "../../utils";

const filter = createFilterOptions();

const employmentDetailsInfo = {
    employmentType: "",
    annualIncome: "",
    expectedLoanAmount: "",
    organisationId: "",
    organisationName: "",
    tenure: "",
    salaryModeId: ""
};




const EmploymentDetailsView = () => {
    const [employementDetails, setEmploymentDetails] = useState(employmentDetailsInfo);
    const { data: employmentTypeList } = useQuery(EMPLOYMENTTYPESLIST, { fetchPolicy: "network-only" });
    const { data: profileDetailsResponse } = useQuery(PROFILEDETAILSQUERY, { fetchPolicy: "network-only" });
    const { data: organisationList } = useQuery(ORGANISATIONNAMELIST, { fetchPolicy: "network-only" });
    const [saveemploymentDetails] = useMutation(UPDATE_EMPLOYEE_DETAILS);
    const navigate = useNavigate();
    const [organisationName, setOrganisation] = useState({});
    const { data: salaryModeList } = useQuery(SALARYMODELSIT, { fetchPolicy: 'network-only' })


    const [org,setOrg] = useState<any>(null)


    const [fetchOrganisationList] = useLazyQuery(ORGANISATIONNAMELIST, {
      fetchPolicy: 'network-only',
    });
  
  
  
  const loadOptions = async (search: string, loadedOptions: any) => {
      try {
    
        const { data } = await fetchOrganisationList({
          variables: {
            offest: loadedOptions.length,
            limit: 10,
            search,
          },
        });
        
        const options = data.organisation.organisations.map((org: any) => ({
          value: org.id,
          label: org.name,
        }));
        
  
      
        
        return {
          options,
          hasMore: data.organisation.organisations.length === 1 && data.organisation.organisations.find(((item: any) => item.name === "Other"))
          ? false
          : loadedOptions.length < data.organisation.totalCount
        };
      } catch (error) {
        console.error('Error loading options:', error);
        return {
          options: [],
          hasMore: false,
        };
      }
    }

    const [toast, setToast] = useState({
        open: false,
        vertical: 'top',
        horizontal: 'center',
    });


    useEffect(() => {
        if (profileDetailsResponse) {

            setEmploymentDetails((prev) => {
                return {
                    ...prev,
                    employmentType: profileDetailsResponse?.userDetails?.employmentType?.id,
                    organisationId: profileDetailsResponse?.userDetails?.organisationId,
                    organisationName: profileDetailsResponse?.userDetails?.organisationName,
                    annualIncome: profileDetailsResponse?.userDetails?.annualIncome,
                    expectedLoanAmount: profileDetailsResponse?.userDetails?.expectedLoanAmount,
                    salaryModeId: profileDetailsResponse?.userDetails?.salaryMode?.id,
                    tenure: profileDetailsResponse?.userDetails?.tenure ?? ""
                }
            })


            setOrg({
                    label:profileDetailsResponse?.userDetails?.organisationName,
                    value:profileDetailsResponse?.userDetails?.organisationId
            })
        }

    }, [profileDetailsResponse])


    const handleChange = (
        e: React.ChangeEvent<HTMLSelectElement | HTMLInputElement>
    ) => {
        const { name, value } = e.target;
        // console.log(name, value)
        setEmploymentDetails((prev) => {
            return {
                ...prev,
                [name]: value
            }
        });

    };


    const saveEmploymentDetails = () => {

        saveemploymentDetails({
            variables: {
                input: {
                    ...employementDetails,
                    annualIncome: +employementDetails?.annualIncome,
                    expectedLoanAmount: +employementDetails.expectedLoanAmount,
                    tenure: +employementDetails.tenure,
                    organisationId:org?.value,
                    organisationName:org?.label

                },
            },
        });

        setToast((prev) => {
            return {
                ...prev,
                open: true
            }
        })


    };

 



    const employmentDetailsView = () => {

        return (
            <>
                <div className="profile-menu-card-view">
                    <FormControl fullWidth>
                        <InputLabel id="demo-simple-select-helper-label">Employment Type</InputLabel>
                        <Select
                            required
                            name="employmentType"
                            label="Employment Type"
                            value={employementDetails.employmentType}
                            onChange={(e: any) =>
                                handleChange(e)
                            }
                        >
                            {(employmentTypeList?.employmentType || []).map(
                                (item: any, id: number) => (
                                    <MenuItem value={item.id} key={item.id}>
                                        {item.name}
                                    </MenuItem>
                                )
                            )}
                        </Select>
                    </FormControl>
                    
                     {/** Organization Autocomplete **/}
                        <AsyncSelect
                            onChange={setOrg}
                            value={org}
                            placeholder="Organisation Name"
                            loadOptions={loadOptions}
                            />

                    <FormControl fullWidth className="mt-25" >
                        <InputLabel id="demo-simple-select-helper-label">Salary Mode</InputLabel>
                        <Select
                            required
                            name="salaryModeId"
                            label="Salary Mode"
                            value={employementDetails.salaryModeId}
                            onChange={(e: any) =>
                                handleChange(e)
                            }
                        >
                            {(salaryModeList?.salaryModes || []).map(
                                (item: any, id: number) => (
                                    <MenuItem value={item.id} key={item.id}>
                                        {item.name}
                                    </MenuItem>
                                )
                            )}
                        </Select>
                    </FormControl>

                    <FormControl fullWidth className="mt-25">
                        <TextField
                            label="Annual income"
                            variant="outlined"
                            name='annualIncome'
                            type="number"
                            value={employementDetails.annualIncome}
                            onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleChange(e)}
                            onKeyDown={(e: any) =>
                                ["e", "E", "+", "-", "."].includes(e.key) &&
                                e.preventDefault()
                              }
                        />
                        <div className="fs-12">{employementDetails.annualIncome && convertNumberToWords(employementDetails.annualIncome)}</div>
                    </FormControl>
                    <FormControl fullWidth className="mt-25">
                        <TextField
                            label="Loan Amount"
                            variant="outlined"
                            name='expectedLoanAmount'
                            type="number"
                            value={employementDetails.expectedLoanAmount}
                            onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleChange(e)}
                            onKeyDown={(e: any) =>
                                ["e", "E", "+", "-", "."].includes(e.key) &&
                                e.preventDefault()
                              }
                        />
                        <div className="fs-12">{employementDetails.expectedLoanAmount && convertNumberToWords(employementDetails.expectedLoanAmount)}</div>
                    </FormControl>

                    <FormControl fullWidth className="mt-25">
                        <TextField
                            type="number"
                            label="Tenure"
                            variant="outlined"
                            name='tenure'
                            value={employementDetails.tenure}
                            onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleChange(e)}
                            onKeyDown={(e: any) =>
                                ["e", "E", "+", "-", "."].includes(e.key) &&
                                e.preventDefault()
                            }
                        />
                    </FormControl>

                    <AppButton type="submit" text="Save Changes" className="w-100 app-btn mt-3" onClick={saveEmploymentDetails} />

                </div>

            </>
        )
    }

    const goToPrev = () => {
        navigate(-1);
    }

    return (
        <>
            <Navbar />
            <div className="d-flex jc-center align-center flex-col mt-4">
                <div>
                    <div className="d-flex">
                        <img src={leftArrow} alt="leftArrow" className="cursor-pointer" onClick={() => goToPrev()} />
                        <span className="ml-2">Employment details</span>
                    </div>
                    {employmentDetailsView()}
                </div>
            </div>
            <Snackbar
                anchorOrigin={{ vertical: "top", horizontal: "center" }}
                open={toast.open}
                autoHideDuration={3000}
                onClose={() => setToast((prev) => {
                    return { ...prev, open: false }
                })}
                message="Employment details Updated SuccessFully"
                key={toast.vertical + toast.horizontal}
            />
        </>
    )
}

export default EmploymentDetailsView