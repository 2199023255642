import { gql } from '@apollo/client';

export const BANKLIST = gql`query LenderMatch($loanAmount: Int, $tenure: Int) {
  lenderMatch(loanAmount: $loanAmount, tenure: $tenure) {
    message
    status
    total
    data {
      lenderName
      minLoanEligibilityAmount
      maxLoanEligibilityAmount
      interestRate
      processingFee
      monthlyEmi
      isResolvingCredit
    }
    revolvingCredits {
      lenderName
      maxLoanEligibilityAmount
      interestRate
      processingFee
      monthlyEmi
      isResolvingCredit
      prePaymentCharges
    }
}
  }`