
const env = {
    API_BASE_URL:process.env.REACT_APP_API_URL,
    GIFT_BOX_APP_URL:process.env.REACT_APP_GIFT_BOX_APP_URL,
    KAPTURE_IV:process.env.REACT_APP_KAPTURE_IV,
    KAPTURE_PARTNER_KEY:process.env.REACT_APP_KAPTURE_PARTNER_KEY
}



export default env