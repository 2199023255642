import employmentIcon from "../../assets/employmentIcon.gif";
import "./userDetails.scss";
import React, { useState, useEffect } from "react";
import AppButton from "../../components/AppButton";
import { convertNumberToWords, validateLoanAmount } from "../../utils";
import { ValidationErrorMessage } from "../../ValidationError";
import ProgressBarComponent from "../../components/ProgressBarComponent";
import { useLazyQuery, useMutation, useQuery } from "@apollo/client";
import { SAVEEMPLOYEEDETAILS } from "../../query/UserDetails/userDetailsQuery";
import { EMPLOYMENTTYPESLIST, ORGANISATIONNAMELIST, SALARYMODELSIT } from "../../query/queries";
import { useNavigate } from "react-router-dom";
import { TextField, FormControl, InputLabel, Select, MenuItem, Checkbox, FormControlLabel, Box, Fab, Typography } from '@mui/material';
import AsyncSelect from "../../components/AsyncSelect";
import LogoIcon from "../../components/Logo";
import { jwtDecode, JwtPayload } from "jwt-decode";
import ChatIcon from '@mui/icons-material/Chat';
import env from "../../config/config";


const employmentDetailsInfo = {
  employmentType: null,
  annualIncome: 0,
  expectedLoanAmount: 0,
  tenure: 0,
  organisationId: 0,
  organisationName: "",
};

const checkBoxInputStyle = {
  color: "#020164",
  width: "20px",
  '&.Mui-checked': {
    color: "#020164",
  },
}

const text = `I hereby appoint Weddingloan.com as my authorized representative to receive 
my credit information from CIBIL/Experian/Equifax/CRIF Highmark as and when required. I hereby unconditionally consent to WeddingLoan.com to securely store my credit information, along with the other information, & use my data (including credit information report, personal/employment related information etc., to share my data with banks/NBFCs to determine my eligibility & enable me to apply for various products & services in future), for use in accordance with WeddingLoan.com's T&C and Privacy Policy. I understand that 
I can opt out/unsubscribe from the service.`;

const EmploymentDetails = () => {
  const [employementDetails, setEmploymentDetails] = useState(
    employmentDetailsInfo
  );
  const [isReadMore, setIsReadMore] = useState(false);
  const [saveemploymentDetails, { data: employmentDetailsResponse, loading }] =
    useMutation(SAVEEMPLOYEEDETAILS);
  const { data: employmentTypeList } = useQuery(EMPLOYMENTTYPESLIST, { fetchPolicy: "network-only" });
  const { data: salaryModeList } = useQuery(SALARYMODELSIT, { fetchPolicy: 'network-only' })
  const [isChecked, setIsChecked] = useState(false);
  const [formCompletedStatus, setFormCompletedStatus] = useState(75);
  const navigate = useNavigate();

  const [org, setOrg] = useState<any>(null)

  const [token, setToken] = useState<JwtPayload & { id: string, ecc: string } | null>(null);

  useEffect(() => {
    const storedToken = sessionStorage.getItem('AuthToken');
    if (storedToken) {
      try {
        const decodedToken: JwtPayload & { id: string, ecc: string } = jwtDecode(storedToken);
        setToken(decodedToken);
        // console.log("Token set:", decodedToken);
      } catch (error) {
        console.error("Error decoding token:", error);
      }
    } else {
      console.log("No token found in sessionStorage");
    }
  }, []);


  const [fetchOrganisationList] = useLazyQuery(ORGANISATIONNAMELIST, {
    fetchPolicy: 'network-only',
  });



  const toggleReadMore = () => {
    setIsReadMore(!isReadMore);
  };

  useEffect(() => {
    if (employmentDetailsResponse?.userEmploymentDetail?.status === 200 || employmentDetailsResponse?.userEmploymentDetail?.status === 201) {
      navigate("/loans");
      setFormCompletedStatus(100);

    }
  }, [navigate, employmentDetailsResponse?.userEmploymentDetail?.status]);

  const saveEmploymentDetails = () => {


    saveemploymentDetails({
      variables: {
        input: {
          ...employementDetails,
          organisationId: org?.value,
          organisationName: org?.label
        }
      },
    });
  };

  const submitFn = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    event.stopPropagation();
    saveEmploymentDetails();
  };

  const handleChange = (
    e: React.ChangeEvent<HTMLSelectElement | HTMLInputElement>
  ) => {
    const { name, value } = e.target;
    if (name === "annualIncome" || name === "expectedLoanAmount" || name === "tenure") {
      setEmploymentDetails({ ...employementDetails, [name]: +value });
    } else {
      setEmploymentDetails({ ...employementDetails, [name]: value });
    }
  };

  const loadOptions = async (search: string, loadedOptions: any) => {
    try {

      const { data } = await fetchOrganisationList({
        variables: {
          offest: loadedOptions.length,
          limit: 10,
          search,
        },
      });

      const options = data.organisation.organisations.map((org: any) => ({
        value: org.id,
        label: org.name,
      }));




      return {
        options,
        hasMore: data.organisation.organisations.length === 1 && data.organisation.organisations.find(((item: any) => item.name === "Other"))
          ? false
          : loadedOptions.length < data.organisation.totalCount
      };
    } catch (error) {
      console.error('Error loading options:', error);
      return {
        options: [],
        hasMore: false,
      };
    }
  }



  const isDisabledButton = () => {
    if (
      employementDetails.employmentType !== "" &&
      employementDetails.annualIncome != null &&
      org &&
      employementDetails.expectedLoanAmount != null &&
      isChecked === true &&
      employementDetails.expectedLoanAmount && employementDetails.tenure) {
      return false;
    }
    return true;
  };

  const formView = () => {
    return (
      <>
        <img
          src={employmentIcon}
          alt="employmentIcon-Icon"
          className="icon-gif"
        />
        <div className="fs-24 heading">Employment details</div>
        <Box sx={{ mt: 1 }}>
          <form noValidate onSubmit={submitFn}>
            {/**  Employment Type **/}
            <FormControl
              fullWidth margin="normal"
            >
              <InputLabel id="demo-simple-select-helper-label">Employment Type</InputLabel>
              <Select
                required
                name="employmentType"
                label="Employment Type"
                onChange={(e: any) =>
                  handleChange(e)
                }
              >
                {/* <option value="">Select your employment type</option> */}
                {(employmentTypeList?.employmentType || []).map(
                  (item: any, id: number) => (
                    <MenuItem value={item.id} key={item.id}>
                      {item.name}
                    </MenuItem>
                  )
                )}
              </Select>
            </FormControl>


            {/**  Salary Mode **/}
            <FormControl
              fullWidth margin="normal"
            >
              <InputLabel id="demo-simple-select-helper-label">Salary Mode</InputLabel>
              <Select
                required
                name="salaryModeId"
                label="Salary Mode"
                onChange={(e: any) =>
                  handleChange(e)
                }
              >
                {(salaryModeList?.salaryModes || []).map(
                  (item: any, id: number) => (
                    <MenuItem value={item.id} key={item.id}>
                      {item.name}
                    </MenuItem>
                  )
                )}
              </Select>
            </FormControl>

            {/* <Form.Control.Feedback type="invalid">
              {ValidationErrorMessage.employmentTypeValidation}
            </Form.Control.Feedback> */}

            {/**  Organization Autocomplete **/}
            {/* <Autocomplete
              fullWidth
              selectOnFocus
              onChange={(e: any, newValue: any) => handleInputChange(e, newValue)}
              options={organisationList?.organisation.organisations}
              // inputValue={inputValue}
              filterOptions={(options: any, params: any) => {
                const filtered = filter(options, params);
                const { inputValue } = params;
                // Suggest the creation of a new value
                const isExisting = options.some((option: any) => inputValue.toUpperCase() === option.name.toUpperCase());
                if (inputValue !== '' && !isExisting) {
                  filtered.push({
                    name: inputValue,
                  });
                }

                return filtered;
              }}
              getOptionLabel={(option: any) => {
                // Value selected with enter, right from the input
                if (typeof option === 'string') {
                  return option;
                }
                // Add "xxx" option created dynamically
                if (option.name) {
                  return option.name;
                }
                // Regular option
                return option.name;
              }}
              renderOption={(props: any, option: any) => {
                const { key, ...optionProps } = props;
                return (
                  <li key={key} {...optionProps}>
                    {option?.name}
                  </li>
                );
              }}
              renderInput={(params: any) => (
                <TextField {...params} label="Organisation Name" name="organisationName" margin="normal" />
              )}
            /> */}


            {/** Organization Autocomplete **/}
            <AsyncSelect
              onChange={setOrg}
              value={org}
              placeholder="Organisation Name"
              loadOptions={loadOptions}
            />


            {/**  Annual Income **/}

            <FormControl fullWidth margin="normal">
              <TextField
                className="input-group-form-field details-input"
                name="annualIncome"
                label="Annual Income"
                placeholder="Enter your annual income"
                aria-label="annualIncome"
                aria-describedby="basic-addon1"
                type="number"
                required
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleChange(e)}
                onKeyDown={(e: any) =>
                  ["e", "E", "+", "-", "."].includes(e.key) &&
                  e.preventDefault()
                }
                error={employementDetails.annualIncome !== 0 && validateLoanAmount(employementDetails.annualIncome, 100000000) === false}
                helperText={employementDetails.annualIncome !== 0 && validateLoanAmount(employementDetails.annualIncome, 100000000) === false ? ValidationErrorMessage.annualIncomeValidation : ""}
              />
            </FormControl>
            {/* <Form.Control.Feedback type="invalid">
                {ValidationErrorMessage.annualIncomeValidation}
              </Form.Control.Feedback> */}
            <div className="fs-12">{(employementDetails.annualIncome !== 0) && convertNumberToWords(employementDetails.annualIncome)}</div>

            {/**  Expected Loan Amount **/}
            <FormControl fullWidth margin="normal">
              <TextField
                label="Loan amount"
                name="expectedLoanAmount"
                placeholder="Enter your expected loan amount"
                aria-label="Loan amount"
                aria-describedby="basic-addon1"
                type="number"
                required
                onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                  handleChange(e)
                }
                className="input-group-form-field details-input"
                onKeyDown={(e: any) =>
                  ["e", "E", "+", "-", "."].includes(e.key) &&
                  e.preventDefault()
                }
                error={employementDetails.expectedLoanAmount !== 0 && validateLoanAmount(employementDetails.expectedLoanAmount, 100000000) === false}
                helperText={employementDetails.expectedLoanAmount !== 0 && validateLoanAmount(employementDetails.expectedLoanAmount, 100000000) === false ? ValidationErrorMessage.annualIncomeValidation : ""}

              />
              <div className="fs-12">{(employementDetails.expectedLoanAmount !== 0) && convertNumberToWords(employementDetails.expectedLoanAmount)}</div>
            </FormControl>
            {/* tenure */}

            <FormControl fullWidth margin="normal">
              <TextField
                label="Tenure In Year"
                name="tenure"
                placeholder="Enter your Tenure Period"
                aria-label="Tenure"
                aria-describedby="basic-addon1"
                type="number"
                required
                onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                  handleChange(e)
                }
                className="input-group-form-field details-input"
                onKeyDown={(e: any) =>
                  ["e", "E", "+", "-", "."].includes(e.key) &&
                  e.preventDefault()
                }
              // error={employementDetails.expectedLoanAmount !== 0 && validateLoanAmount(employementDetails.expectedLoanAmount, 100000000) === false}
              // helperText={employementDetails.expectedLoanAmount !== 0 && validateLoanAmount(employementDetails.expectedLoanAmount, 100000000) === false ? ValidationErrorMessage.annualIncomeValidation : ""}

              />
            </FormControl>



            {/* <div>{(employementDetails.expectedLoanAmount !== 0) && convertNumberToWords(employementDetails.expectedLoanAmount)}</div> */}

            <div className="description-text pd-0">
              <FormControlLabel
                sx={{ marginLeft: "0px" }}
                control={
                  <Checkbox

                    name="isChecked"
                    checked={isChecked}
                    sx={checkBoxInputStyle}
                    onChange={() => setIsChecked(!isChecked)}
                    // className="check-box-input"
                    disableRipple
                  />
                }
                label={
                  <Box sx={{ ml: 1, fontSize: "12px" }}>
                    {isReadMore ? (
                      <>
                        <span>
                          I hereby appoint Weddingloan.com as my authorized
                          representative to receive my credit information from
                          CIBIL/Experian/Equifax/CRIF Highmark as and when required.
                          I hereby unconditionally consent to WeddingLoan.com to
                          securely store my credit information, along with the other
                          information, & use my data (including credit information
                          report, personal/employment related information etc., to
                          share my data with banks/NBFCs to determine my eligibility
                          & enable me to apply for various products & services in
                          future), for use in accordance with WeddingLoan.com's{" "}
                          <a href="" className="app-text-color">
                            T&C
                          </a>{" "}
                          and{" "}
                          <a href="" className="app-text-color">
                            Privacy Policy
                          </a>
                          . I understand that I can opt out/unsubscribe from the
                          service.
                        </span>

                      </>
                    ) : (text.slice(0, 103))}
                    <span
                      onClick={toggleReadMore}
                      className="app-text-color cursor-pointer fs-12"
                    >
                      {isReadMore ? " Read less" : " Read more..."}
                    </span>
                  </Box>
                }
              />
            </div>
            <Box sx={{ mt: 3 }}>
              <AppButton
                text="Proceed"
                className="w-100 app-btn"
                isDisabled={isDisabledButton()}
                loading={loading}
                type="submit"
              />
            </Box>
            {/* <Box sx={{ mt: 2 }} className="text-center mt-3">
              <a
                href="#"
                className="app-text-color fs-14"
              // onClick={(e: React.MouseEvent<HTMLElement>) => handleneedHelp(e)}
              >
                Need help?
              </a>
            </Box> */}
          </form>
        </Box>

      </>
    );
  };

  return (
    <>
      <div className="basic-details-view">
        <div className="app-logo w-100">
          <LogoIcon />
          {/* <img src={Logo} alt="logo" />
          Logo */}
        </div>
        <div>
          <div className="app-progress-bar w-100">
            <ProgressBarComponent completedStatus={formCompletedStatus} />
          </div>
          <div className="details-form-view">{formView()}</div>
        </div>
      </div>
      <Fab
        color="primary"
        variant="extended"
        style={{
          position: 'fixed',
          bottom: 16,
          right: 16,
          display: 'flex',
          zIndex: 1000,
        }}
        onClick={() => {
          const customerCode = token?.ecc.replaceAll("+", "%2B") || 'cus_code';
          const iv = env.KAPTURE_IV || 'cus_iv';

          const url = `https://selfserveapp.kapturecrm.com/web-view/webview_chat.html?&data-supportkey=${env.KAPTURE_PARTNER_KEY}&chat-for=TICKET&data-server=Indian&script_type=RNF&customer_code=${customerCode}&iv=${iv}`;
          window.open(url, '_blank', 'noopener,noreferrer');
        }}
      >
        <Box sx={{ display: 'flex', gap: '0.5rem', alignItems: 'center', justifyContent: 'center' }}>
          <ChatIcon />
          <Typography textTransform={'capitalize'}>
            Chat
          </Typography>
        </Box>
      </Fab>
    </>
  );
};

export default EmploymentDetails;
