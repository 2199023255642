import { gql } from '@apollo/client';


export const VIEWBANKDETAILS = gql`mutation ParticularPlan($input: ParticularPlanInput) {
  particularPlan(input: $input) {
    particularPlanErrors {
      message
    }
    message
    status
    data {
      id
      lenderName
      maxLoanEligibilityAmount
      minLoanEligibilityAmount
      processingFee
      interestRate
      minAge
      maxAge
      salary
    }
  }
}`