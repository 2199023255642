import Navbar from "../../components/Navbar/Navbar";
import "./profilepage.scss";
import rightArrowIcon from "../../assets/rightArrow.svg";
import basicDetailsIcon from "../../assets/basicDetailsIcon.svg";
import privacyPolicy from "../../assets/privacyPolicy.svg";
import termscondtions from "../../assets/termscondtions.svg";
import employmentDetails from "../../assets/employmentDetails.svg";
import locationDetails from "../../assets/locationDetails.svg";
import profileSettingIcon from "../../assets/profileSettingIcon.svg";
import profileAccountIcon from "../../assets/profileAccountIcon.svg";
import { useNavigate } from "react-router-dom";
import { useQuery } from "@apollo/client";
import { PROFILEDETAILSQUERY } from "../../query/ProfileDetails/profileDetailsQuery";
import { useEffect, useState } from "react";
import LoadingModalView from "../../components/LoadingModal";
import { Box } from "@mui/material";


const ProfilePage = () => {
    const { data: profileDetailsResponse } = useQuery(PROFILEDETAILSQUERY, { fetchPolicy: "network-only" });
    const navigate = useNavigate();

    const [name, setName] = useState('')
    const [isLoading, setLoading] = useState(true)


    useEffect(() => {

        if (profileDetailsResponse) {
            setName(profileDetailsResponse?.userDetails?.firstName + " " + profileDetailsResponse?.userDetails?.lastName)
            setLoading(false)
        }
    }, [profileDetailsResponse])

    const handleProfilePage = (page: number) => {
        if (page === 1) {
            navigate("/profile/basic-details");
        } else if (page === 2) {
            navigate("/profile/location-details");
        } else if (page === 3) {
            navigate("/profile/employment-details");
        } else if (page === 4) {
            navigate("/profile/support")
        }
        else if (page === 5) {
            navigate("/terms-conditions")
        }
        else if (page === 6) {
            navigate("/privacy-policy")
        }

    }

    return (
        <>
            <Navbar />
            {isLoading ? <LoadingModalView isOpen={true} lenderName=" " /> : <div className="pd-30 
            ">
                <div className="profile-menu-card-view">
                    <div className="d-flex align-center">
                        <div className="profile-img-view">
                            <img src={profileAccountIcon} alt="profileAccountIcon" />
                        </div>
                        <div className="profile-name">Hi, {name}</div>
                    </div>
                    <div className="mt-25">
                        <div className="d-flex jc-sb align-center profile-menu" onClick={() => handleProfilePage(1)}>
                            <div>
                                <img src={basicDetailsIcon} alt="basicDetailsIcon" />
                                <span className="ml-20">Basic details</span>
                            </div>
                            <div>
                                <img src={rightArrowIcon} alt="rightArrowIcon" />
                            </div>
                        </div>
                        <div className="d-flex jc-sb align-center profile-menu" onClick={() => handleProfilePage(2)}>
                            <div>
                                <img src={locationDetails} alt="locationDetailsIcon" />
                                <span className="ml-20">Location details</span>
                            </div>
                            <div>
                                <img src={rightArrowIcon} alt="rightArrowIcon" />
                            </div>
                        </div>
                        <div className="d-flex jc-sb align-center profile-menu" onClick={() => handleProfilePage(3)}>
                            <div>
                                <img src={employmentDetails} alt="employmentDetailsIcon" />
                                <span className="ml-20">Employment details</span>
                            </div>
                            <div>
                                <img src={rightArrowIcon} alt="rightArrowIcon" />
                            </div>
                        </div>
                        <div className="d-flex jc-sb align-center profile-menu" onClick={() => handleProfilePage(4)}>
                            <div>
                                <img src={profileSettingIcon} alt="profileSettingIconIcon" />
                                <span className="ml-20">Support</span>
                            </div>
                            <div>
                                <img src={rightArrowIcon} alt="rightArrowIcon" />
                            </div>
                        </div>
                        <div className="d-flex jc-sb align-center py-15 profile-menu">
                            <Box component="a" target="_blank" href="/terms-conditions"
                                sx={{ cursor: "pointer", textDecoration: 'none', color: 'black' }}>
                                <img src={termscondtions} alt="termscondtionsIcon" />
                                <span className="ml-20">Terms & conditions</span>
                            </Box>
                        <div>
                                <img src={rightArrowIcon} alt="rightArrowIcon" />
                            </div>
                        </div>
                        <div className="d-flex jc-sb align-center py-15 profile-menu">
                            <Box component="a" target="_blank" href="/privacy-policy"
                                sx={{ cursor: "pointer", textDecoration: 'none', color: 'black' }}>
                                <img src={privacyPolicy} alt="privacyPolicyIcon" />
                                <span className="ml-20">Privacy policy</span>
                            </Box>
                            <div>
                                <img src={rightArrowIcon} alt="rightArrowIcon" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>}
        </>
    )
}

export default ProfilePage;