import React from 'react';
import './App.scss';
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import LandingPage from './pages/LandingPage/LandingPage';
import Login from './pages/Login/Login';
import BasicDetails from './pages/UserDetails/BasicDetails';
import LocationDetails from './pages/UserDetails/LocationDetails';
import EmploymentDetails from './pages/UserDetails/EmploymentDetails';
import ProfilePage from './pages/ProfilePage/ProfilePage';
import BasicDetailsView from './pages/ProfilePage/BasicDetailsView';
import LocationDetailsView from './pages/ProfilePage/LocationDetailsView';
import SupportPage from './pages/ProfilePage/SupportPage';
import EmploymentDetailsView from './pages/ProfilePage/EmploymentDetailsView';
import ViewDetailsPage from './pages/ViewDetails/ViewDetailsPage';
import CashePage from './pages/BankListingComponent/CashePage';
import LoanComponent from './pages/LoanComponent/LoanComponent';
import Tools from './pages/Tools/Tools';
import LoanHistory from './pages/LoanComponent/LoanHistory';

import "@fontsource/poppins"; 
import "@fontsource/poppins/400.css";
import "@fontsource/poppins/400-italic.css"; 
import { getToken } from './utils';
import CreditScore from './pages/CreditScore/CreditScore';
import PrivacyPolicy from './components/PrivacyPolicy';
import TermsAndConditons from './components/TermsAndConditons';

interface RouteProps {
  element: React.ComponentType<any>;
  [key: string]: any;
}


const isAuthenticated = () => {
  return getToken();
};

const ProtectedRoute : React.FC<RouteProps> = ({ element: Element, ...rest }) => {
  return isAuthenticated() ? <Element {...rest} /> : <Navigate to="/login" replace />;
};

const PublicRoute : React.FC<RouteProps> = ({ element: Element, ...rest }) => {
  return isAuthenticated() ? <Navigate to="/loans" replace /> : <Element {...rest} />;
};

const publicRoutes = [
  { path: '/', element: <LandingPage /> },
  { path: '/login', element: <Login /> },
  {path:'/credit-score',element:<CreditScore/>},
  { path: '/terms-conditions', element: <TermsAndConditons /> },
  { path: '/privacy-policy', element: <PrivacyPolicy /> },
];

const privateRoutes = [
  { path: '/basic-details', element: <BasicDetails /> },
  { path: '/loans', element: <LoanComponent /> },
  { path: '/location-details', element: <LocationDetails /> },
  { path: '/employment-details', element: <EmploymentDetails /> },
  { path: '/profile', element: <ProfilePage /> },
  { path: '/profile/basic-details', element: <BasicDetailsView /> },
  { path: '/profile/location-details', element: <LocationDetailsView /> },
  { path: '/profile/employment-details', element: <EmploymentDetailsView /> },
  { path: '/loan-details-view', element: <ViewDetailsPage /> },
  { path: '/cashe', element: <CashePage /> },
  { path: '/profile/support', element: <SupportPage /> },
  { path: '/tools', element: <Tools /> },
  { path: '/loan-history', element: <LoanHistory /> },
  { path: '/support', element: <SupportPage /> },

];

function App() {
  return (
    <BrowserRouter>
      <Routes>
        {publicRoutes.map(({ path, element }) => (
          <Route key={path} path={path} element={<PublicRoute element={() => element} />} />
        ))}
        {privateRoutes.map(({ path, element }) => (
          <Route key={path} path={path} element={<ProtectedRoute element={() => element} />} />
        ))}
        <Route path="*" element={<Navigate to="/" replace />} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;